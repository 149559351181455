/* fonts */

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: local('Inter'), url(./fonts/Inter-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(./fonts/Inter-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(./fonts/Inter-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: local('Inter'), url(./fonts/Inter-ExtraBold.otf) format('opentype');
}

/* layout */

* {
  box-sizing: border-box;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}

body,
p {
  line-height: 1.4em;
}

a {
  color: #fe630c;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

h2 {
  font-weight: 600;
  font-size: 1.86em;
}

h3 {
  font-weight: 600;
  font-size: 1.4em;
}

strong {
  font-weight: 600;
}

hr {
  color: #c0c0c0;
  background-color: #c0c0c0;
  height: 1px;
  border: none;
  margin: 2em 0 5em;
}

@media (min-width: 768px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  body {
    font-size: 15px;
  }
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: 300;
  font-style: normal;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 100000000000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__select__menu-portal {
  z-index: 10002 !important;
}

.ReactModal__Content.bordered {
  border-radius: 16px !important;
}

/* hide recaptcha v3:
   https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge-what-is-allowed
*/
.grecaptcha-badge {
  visibility: hidden;
}

/* Fix print settings - Used to generate invoice PDFs */
@page {
  size: A4;
  margin: 0;
}

/* VideoAsk widget */
button[class*='videoask-embed__button'] span[class*='videoask-embed__emoji'] {
  animation: none !important;
}
